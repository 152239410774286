import { template as template_8f166f84b3ea4923b8691af04a48a1b8 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_8f166f84b3ea4923b8691af04a48a1b8(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
