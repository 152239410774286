import { template as template_c236dd73a050446b9b8ddaa22dfa6aa1 } from "@ember/template-compiler";
const FKText = template_c236dd73a050446b9b8ddaa22dfa6aa1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
